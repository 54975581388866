<template>
  <div>
    <!-- Page Header -->
    <base-page-header title="LowerMyBills Billing Tool">
      <template v-slot:buttons>
        <div class="row mt-3 mt-md-0">
          <div class="col-md-12 d-flex justify-content-md-end">
            <base-button title="Settings" @click-btn="modals.setting = true" action="secondary" />
            <base-button title="Load leads" action="primary" @click-btn="loadLeads" :loading="ui.loadLeads" class="ms-2" />
          </div>
        </div>
      </template>
    </base-page-header>

    <!-- Page Spinner -->
    <page-spinner v-if="ui.loading" />

    <!-- Page Content -->
    <base-page-content v-else>
      <template v-slot:content>
        <!-- Page Table -->
        <base-table :columns="columns">
          <template v-slot:table-body v-if="agents.length">
            <tr v-for="agent in agents" :key="'bill_' + agent.id">
              <td>{{ agent.fullName }}</td>
              <td>{{ agent.nmls }}</td>
              <td>{{ agent.lastUpdated ? formatDate(agent.lastUpdated) : 'none' }}</td>
              <td>{{ convertToCurrency(agent.lastCharged) }}</td>
              <td>{{ agent.leads.length }} | {{ convertToCurrency(agent.charges) }}</td>
              <td>
                <div class="d-flex">
                  <base-button title="Charge" action="secondary-default" @click-btn="charge(agent)" size="sm" />
                </div>
              </td>
            </tr>
          </template>
          <template v-slot:table-body v-else>
            <tr>
              <td colspan="6" class="text-center">
                <div class="m-5">
                  <strong class="no-result">No Records Found...</strong><br>
                </div>
              </td>
            </tr>
          </template>
        </base-table>
      </template>
    </base-page-content>
    <transition name="component-fade" mode="out-in">
      <lower-my-bills-charge-modal v-if="modals.charge"
                             :agent="selectedAgent"
                             @close="modals.charge = false"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <SettingModal v-if="modals.setting" @close="closeSettingModal"/>
    </transition>
  </div>
</template>

<script>
import PageSpinner from "../../components/pageSpinner.vue";
import BasePageContent from "../../components/BasePageContent.vue";
import BasePageHeader from "../../components/BasePageHeader.vue";
import BaseTable from "../../components/BaseTable.vue";
import LowerMyBillsChargeModal from "@/views/lowerMyBills/modals/LowerMyBillsChargeModal";
import SettingModal from "@/views/lowerMyBills/modals/SettingModal";
import BaseButton from '../../components/BaseButton.vue';
export default {
  name: "LowerMyBillsTool",
  components: {
    BasePageHeader, BasePageContent, BaseTable, PageSpinner, LowerMyBillsChargeModal, SettingModal,
    BaseButton
  },
  data() {
    return {
      ui: {
        loading: false,
        loadLeads: false,
      },
      columns: ["Agent", "NMLS", "Last updated on", "Last charged", "Total Due", ""],
      agents: [],
      selectedAgent: null,
      modals: {
        charge: false,
        setting: false,
      },
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;
      this.$http.get('/api/v1/lower-my-bills-tool')
        .then((res) => { this.agents = res.data.agents })
        .catch((err) => { console.log(err) })
        .finally(() => { this.ui.loading = false })
    },
    loadLeads() {
      this.ui.loadLeads = true
      this.$http.get('/api/v1/lower-my-bills-tool/update-data')
        .then(() => { this.getData() })
        .catch((err) => { console.log(err) })
        .finally(() => { this.ui.loadLeads = false })
    },
    charge(agent) {
      this.selectedAgent = agent
      this.modals.charge = true
    },
    closeSettingModal(update = false) {
      console.log(update)
      if (update) {
        this.getData()
      }
      this.modals.setting = false
    }
  },
  beforeMount() {
    this.getData()
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  padding-top: 12px;
}

::v-deep {
    .table-block {
        .table {
            .btn {
              &.btn-secondary {
                min-width: 100px;
              }

              &.btn-control {
                width: 36px;
                height: 36px;

                span {
                  font-size: 10px;
                  width: 10px;
                  height: 10px;
                }
              }
            }
        }
    }
}
</style>
