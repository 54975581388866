<template>
    <div class="table-block page-content__table-wrapper">
        <table class="table page-content__table">
            <thead>
                <tr>
                    <th v-for="(column, index) in columns" :key="index">{{ column }}</th>
                </tr>
            </thead>
            <tbody>
                <slot name="table-body"></slot>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'BaseTable',
    props: {
        columns: {
            type: Array,
            default: () => []
        }
    }
}
</script>
